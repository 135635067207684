import React from 'react'
import Card from "@mui/material/Card";
import {CardActionArea, CardMedia, Chip} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';

export const AddNew = () => {
    return (<Link
        className='flex-1 relative'
        to={'new'}>
        <Card
            sx={{border: 'none', height: 320, display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        >
            <CardActionArea sx={{display: 'flex', transform: 'scale(5)'}}>
                <AddIcon fontSize='inherit'/>
            </CardActionArea>
        </Card>
    </Link>)
}