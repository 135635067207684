import React, {useState} from 'react'
import Modal from '@mui/material/Modal';
import Typography from "@mui/material/Typography";
import {Box, CardMedia} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';


import Fade from '@mui/material/Fade';
import {Form} from './Form'
import {createBooking} from "../../../api";
import {Success} from "./Success";
import {Error} from "./Error";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
    outline: 0,
};
export const PlacePageModal = ({isOpen, setOpen, ad}) => {
    const [isLoading, setLoading] = useState(false)
    const [isSuccess, setSuccess] = useState(false)
    const [isError, setError] = useState(false)

    const onSubmit = async (params) => {
        setLoading(true)
        const response = await createBooking(params)

        if (response.status === 'success') {
            setLoading(false)
            setSuccess(true)
            return
        }
        if (response.status === 'error') {
            setLoading(false)
            setError((true))
        }

    }

    const onClose = () => {
        setOpen(false)
        setError(false)
        setLoading(false)
        setSuccess(false)
    }

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
        >
            <Fade in={isOpen}>
                <Box
                    className='flex flex-wrap'
                    sx={style}>
                    {!isLoading && !isSuccess && !isError && <Form ad={ad} onSubmit={onSubmit}/>}
                    {isLoading && <CircularProgress/>}
                    {isSuccess && <Success />}
                    {isError && <Error />}
                </Box>
            </Fade>
        </Modal>

    )
}

