import React from 'react';
import Map, {Marker} from 'react-map-gl';
import mapboxgl from "mapbox-gl";

export const VenueMap = ({longitude, latitude, title}) => {
    const popup = (text) => {
        return new mapboxgl.Popup().setText(text);
    };

    return longitude && latitude && (
        <div
            className='lg:w-[600px] h-[410px] w-full'
        >
            <Map
                mapboxAccessToken={process.env.MAP_TOKEN}
                cooperativeGestures
                initialViewState={{
                    longitude,
                    latitude,
                    zoom: 12
                }}
                 style={{height: '100%', width: '100%'}}
                mapStyle="mapbox://styles/mapbox/streets-v9"
            >
                <Marker
                    key={title}
                    longitude={longitude}
                    latitude={latitude}
                    popup={popup(title)}
                    color='red'
                    onClick={(args) => console.log(args)}
                />
            </Map>
        </div>

    )
}
