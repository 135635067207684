import React from 'react';
import SearchIcon from "@mui/icons-material/Search";
import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";
import {OptionSelector} from "./OptionSelector";
import {useTranslation} from "react-i18next";

const CustomButtonRoot = styled(Button)(
    ({theme}) => `
    background: rgb(234, 91, 112);
    border-radius: 100%;
    transform: scale(0.7);
    &:hover {
        background: rgb(255, 142, 158);
}`)

export const SearchFilter = (props) => {
    const {cities, setCity, ages, setAge, activities, setActivity, selectedAge, selectedCity, selectedActivity, onSearch} = props;
    const {t} = useTranslation();

    return (
        <div
            id='search-bar-holder'
            className='lg:flex items-center justify-center mt-5 hidden'>
            <div
                id='search-bar'
                className='shadow shadow-gray-300 rounded-3xl w-[850px] h-[76px] flex justify-between bg-white'>
                <OptionSelector
                    preselectedValue={selectedCity}
                    name={t('filter.city')}
                    description={t('filter.selectCity')}
                    options={cities}
                    onOptionChange={setCity}/>
                <OptionSelector
                    preselectedValue={selectedAge}
                    name={t('filter.age')}
                    description={t('filter.selectAge')}
                    options={ages}
                    onOptionChange={setAge}/>
                <OptionSelector
                    preselectedValue={selectedActivity}
                    name={t('filter.activity')}
                    description={t('filter.selectActivity')}
                    options={activities}
                    onOptionChange={setActivity}/>
                <CustomButtonRoot variant="contained" onClick={onSearch}>
                    <SearchIcon fontSize='large'/>
                </CustomButtonRoot>
            </div>
        </div>
    )
}