import React, {ReactNode} from 'react';
import {Link} from "react-router-dom";
import {LanguageSwitcher} from "../LanguageSwitcher";
import {Login} from "../Login";

export const Header = ({children, withBorder}: { children?: ReactNode, withBorder?: boolean }) => (
        <header className={`min-h-20 items-center flex p-4 w-full ${withBorder ? 'shadow shadow-gray-10' : ''}`}>
            <nav className="items-center flex w-full justify-between">
                <Link to='/'>
                    <div className="flex-1">
                        <div
                            style={{
                                fontFamily: 'Cherry Bomb One'
                            }}
                            className="text-4xl flex justify-center items-center">
                            <div
                                className='w-[80px] h-[60px] bg-contain bg-center inline-block  bg-no-repeat'
                                style={{
                                    backgroundImage: `url(https://res.cloudinary.com/dhlzvktzj/image/upload/v1710584946/sunni/stars2_jfrilw.png)`,
                                }}>
                            </div>
                            Trallid.ee
                        </div>
                    </div>
                </Link>
                <div className='flex-1'>
                    {children}
                </div>
                <div className="flex justify-end">
                    <LanguageSwitcher/>
                </div>
                {/*<div className='ml-3'>*/}
                {/*    <Login/>*/}
                {/*</div>*/}
            </nav>
        </header>
)
