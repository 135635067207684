import React from 'react'
import {Icons} from "./Icons";
import {VenueMap} from "./VenueMap";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import {Ad} from "../../types";

export const Description = (ad: Ad) => {
    const {
        description,
        title,
        longitude,
        latitude,
        name
    } = ad;
    const areIcons = ad.max_people || ad.min_time || ad.total_area || ad.room_number;

    return (
        <div
            className='lg:max-w-[650px]'
            id="content">
            <Typography
                className='text-5xl lg:mb-5 pl-2'
                variant='h4'
            >
                {title}
            </Typography>

            <List sx={{width: '100%', bgcolor: 'background.paper'}}>
                {/*Выглядит не красиво и я пока это не поддерживаю - поэтмоу удаляю пока*/}
                {/*{areIcons && <>*/}
                {/*    <Divider variant="inset" component="li"/>*/}
                {/*    <Icons*/}
                {/*        people={ad.max_people}*/}
                {/*        time={ad.min_time}*/}
                {/*        area={ad.total_area}*/}
                {/*        room={ad.room_number}*/}
                {/*    />*/}
                {/*    <Divider variant="inset" component="li"/>*/}
                {/*</>}*/}


                <ListItem alignItems="flex-start">
                    <ListItemText
                        primary={<div className='font-bold'>Description</div>}
                        secondary={
                            <React.Fragment>
                                <Typography
                                    sx={{display: 'inline'}}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    {description} </Typography>
                            </React.Fragment>
                        }
                    />
                </ListItem>

                {ad?.price_includes?.length && <>
                    <ListItem alignItems="flex-start">
                        <ListItemText
                            primary={<div className='font-bold'>Price includes:</div>}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{display: 'inline'}}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        <ul>
                                            {
                                                ad?.price_includes?.map((incl, key) => <li key={key}>{incl}</li>)
                                            }
                                        </ul>
                                    </Typography>

                                </React.Fragment>
                            }
                        />
                    </ListItem></>}
                {
                    ad?.addons?.length && <>
                        <ListItem alignItems="flex-start">
                            <ListItemText
                                primary={<div className='font-bold'>Add-ons</div>}
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{display: 'inline'}}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            <ul>
                                                {
                                                    ad?.addons?.map((addon) => <li>{addon}</li>)
                                                }
                                            </ul>
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    </>
                }

                {
                    ad?.rules?.length && <>
                        <ListItem alignItems="flex-start">
                            <ListItemText
                                primary={<div className='font-bold'>Rules</div>}
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{display: 'inline'}}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                            <ul>
                                                {
                                                    ad?.rules?.map((rule) => <li>{rule}</li>)
                                                }
                                            </ul>
                                        </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                    </>
                }


                <ListItem>
                    <VenueMap title={title} longitude={longitude} latitude={latitude}/>
                </ListItem>
            </List>

        </div>
    )
}
