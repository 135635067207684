import React from 'react'
import Typography from "@mui/material/Typography";
import Switch from '@mui/material/Switch';
import {CardActionArea, CardMedia, FormControlLabel} from "@mui/material";
import {Props} from "./types";
import {Ad} from "./../../types";
import {useTranslation} from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {Link} from "react-router-dom";
import {PreviewCard} from "../../components/PreviewCard";


export const Ads = ({ads, totalAdNumber, setShowMap, showMap}: Props) => {
    const {t} = useTranslation();

    return (
        <div className='px-5 min-w-[70%] w-full overflow-scroll'>
            <div className='py-5 justify-between flex'>
                <Typography>
                    {t('ads.showing')} {ads.length} {t('ads.out-of')} {totalAdNumber} {t('ads.ads')}
                </Typography>
                <div className='hidden lg:block'>
                    <Typography>
                        <FormControlLabel
                            control={<Switch checked={showMap} onChange={(e) => setShowMap(e.target.checked)}/>}
                            label="Show map" labelPlacement="start"
                        />
                    </Typography>
                </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4'>
                {
                    ads.map(({district, title, id, hero_images, name}: Ad) => (
                        <PreviewCard
                            key={title + id}
                            id={id}
                            to={`/places/${id}`}
                            title={title}
                            district={district}
                            image={hero_images[0]}
                            name={name}
                        />
                    ))
                }
            </div>
        </div>

    )
}
