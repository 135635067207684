{
  "landing": {
    "hero-title": "Найдите приключения, праздники и познавательный опыт",
    "title2": "Забронируйте уникальные впечатления",
    "subtitle2": "Которые вы долго не забудете",
    "bouldering": "Скалолазание",
    "paintball": "Пинтбол",
    "escape-rooms": "Квесты",
    "kids-play-rooms": "Детские игровые комнаты",
    "museums": "В музеях",
    "outside": "На открытом воздухе",
    "vr": "Виртуальная реальность",
    "calligraphy": "Каллиграфия"
  },
  "filter": {
    "all": "Все",
    "city": "Город",
    "selectCity": "Выберите город",
    "age": "Возраст",
    "selectAge": "Для кого",
    "activity": "Вид деятельности",
    "selectActivity": "Тип праздника",
    "Tallinn": "Таллинн",
    "kids": "Для детей",
    "adults": "Для взрослых",
    "teenager": "Для подростков",
    "birthdays": "Детские праздники",
    "sport": "Спортивные мероприятия",
    "art": "Крафт и дизайн",
    "education": "Образовательные",
    "teambuilding": "Тим билдинг",
    "picnic": "Пикник",
    "events": "Мероприятия"
  },
  "ads": {
    "showing": "Отображаются",
    "out-of": "из",
    "ads": "объявлений"
  },
  "selectors": {
    "find": "Найти"
  },
  "footer": {
    "contacts": "Контакты",
    "terms": "Условия использования",
    "faq": "Частые вопросы"
  },
  "contacts": {
    "title": "Контакты"
  },
  "faq": {
    "title": "Частые вопросы",
    "question-1": "- Что здесь происходит?",
    "answer-1": "- На сайте собран каталог предпрития для проведения праздников например корпоративов или день рождений",
    "question-2": "- Нужно ли платить или регистрироваться?",
    "answer-2": "- Нет, мы каталог, помогаем найти места для проведения праздников и с ними связаться",
    "question-3": "- Когда я оправляю письмо на странице предприятия с датой и комментарием что происходит?",
    "answer-3": "- Мы перенаправляем его на емейл предприятия, чтобы они смогли с вами связаться"
  },
  "terms": {
    "title": "Условия использования сайта:",
    "paragraph-1": "Владельцем данного сайта является FIE Mikhail Stepanov (16370584), проживающий в Эстонии. Сайт предоставляет свободный и бесплатный доступ для всех пользователей интернета. Основная цель сайта - предоставить услугу каталога предприятий, где можно организовать праздники и корпоративные мероприятия, а также обеспечить возможность связаться с данными предприятиями.",
    "paragraph-2": "Важно отметить, что данный сайт не собирает или хранит пользовательские данные. Вся информация, предоставленная на сайте, является общедоступной и может быть изменена предприятиями без предварительного уведомления. Сайт не несет ответственности за достоверность информации о местах и времени проведения праздников, а также за подтверждение их реальности. Пользователи должны самостоятельно проверять информацию и уточнять детали напрямую у предприятий.",
    "paragraph-3": "Используя данный сайт, вы автоматически соглашаетесь с вышеуказанными условиями. Если у вас есть какие-либо вопросы или предложения относительно использования сайта, пожалуйста, свяжитесь с нами через предоставленные контактные данные"
  }
}