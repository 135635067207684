const CARDS = [
    {
        key: 'bouldering',
        photoUrl: 'https://res.cloudinary.com/dhlzvktzj/image/upload/v1710586853/sunni/bouldering_hsus6e.jpg'
    },
    {
        key: 'paintball',
        photoUrl: 'https://res.cloudinary.com/dhlzvktzj/image/upload/v1710586853/sunni/paintball_onxx8z.jpg'
    },
    {
        key: 'escape-rooms',
        photoUrl: 'https://res.cloudinary.com/dhlzvktzj/image/upload/v1710586842/sunni/escaperoom_m4gf8j.jpg'
    },
    {
        key: 'kids-play-rooms',
        photoUrl: 'https://res.cloudinary.com/dhlzvktzj/image/upload/v1710586844/sunni/mangutube_zpmv7e.jpg'
    },
    {
        key: 'museums',
        photoUrl: 'https://res.cloudinary.com/dhlzvktzj/image/upload/v1710586846/sunni/museum_jd4knr.jpg'
    },
    {
        key: 'outside',
        photoUrl: 'https://res.cloudinary.com/dhlzvktzj/image/upload/v1710586844/sunni/hourse_qvkplh.jpg'
    },
    {
        key: 'vr',
        photoUrl: 'https://res.cloudinary.com/dhlzvktzj/image/upload/v1710586853/sunni/vr_hlejt2.jpg'},
    {
        key: 'calligraphy',
        photoUrl: 'https://res.cloudinary.com/dhlzvktzj/image/upload/v1710586853/sunni/calligraphy_zggdvh.jpg'
    },
]

export const CARDS_LIST = [
    'bouldering',
    'paintball',
    'escape-rooms',
    'kids-play-rooms',
    'museums',
    'outside',
    'vr',
    'calligraphy'
]

export const getPhotoByKey = (key: string) => CARDS.find(card => card.key === key).photoUrl