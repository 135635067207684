import {
    initReactI18next,
    useTranslation as useTranslationOrg
} from 'react-i18next'

import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import resourcesToBackend from 'i18next-resources-to-backend'
import ru from './locales/ru/translations.json'
import ee from './locales/ee/translations.json'
import en from './locales/en/translations.json'


export const fallbackLng = 'en'
export const languages = [fallbackLng, 'ru', 'ee']
export const defaultNS = 'translations'

const resources = {
    en: {translations: en},
    ru: {translations: ru},
    ee: {translations: ee}
}

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        debug: true,
        supportedLngs: languages,
        fallbackLng,
        fallbackNS: defaultNS,
        defaultNS
    })