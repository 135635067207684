import React from 'react'
import {useTranslation} from "react-i18next";

const languages = {
    ee: {nativeName: "Eesti", icon: 'https://res.cloudinary.com/dhlzvktzj/image/upload/v1709304493/sunni/estonia_eklyi2.png'},
    en: {nativeName: "English", icon: 'https://res.cloudinary.com/dhlzvktzj/image/upload/v1709304493/sunni/united-kingdom_zko7rq.png'},
    ru: {nativeName: "Русский", icon: 'https://res.cloudinary.com/dhlzvktzj/image/upload/v1709304493/sunni/russia_q7oosv.png'},
}

export const LanguageSwitcher = () => {
    const {t, i18n} = useTranslation();

    return (
        <div className='flex'>
            {Object.keys(languages).map(lng => (
                <div
                    className='w-[30px] h-[30px] bg-cover bg-center mr-2 cursor-pointer'
                    style={{
                        backgroundImage: `url(${languages[lng].icon})`
                    }}
                    onClick={() => i18n.changeLanguage(lng)}
                    key={lng}
                ></div>))}
        </div>
    )
}