import React from 'react'
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LanguageIcon from "@mui/icons-material/Language";

export const Logos = ({website, facebook, instagram}) => (
    <div
        className='text-center mt-4'>
        {facebook && <a href={facebook} target='_blank'>
            <FacebookIcon
                fontSize='large'
                color='primary'
            />
        </a>}
        {instagram && <a href={instagram} target='_blank'>
            <InstagramIcon
                fontSize='large'
                color='primary'
            />
        </a>}
        {website && <a href={website} target='_blank'>
            <LanguageIcon
                fontSize='large'
                color='primary'
            />
        </a>}
    </div>
)