import React, {useState} from 'react'

import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

import {Ad} from "../../types";
import {CardMedia} from "@mui/material";
import {PlacePageModal} from "./PlacePageModal";
import {Logos} from "./Logos";


export const Aside = (ad: Ad) => {
    const {price, id, min_time} = ad;
    const [isOpenModel, setModelOpen] = useState(false);

    return (
        <div
            className='lg:w-[300px] w-full mx-auto sm:max-w-[500px]'>
            <div
                className='sticky top-0'>
                <Card
                    className='w-90%'
                    sx={{
                        boxShadow: '0 0 0 1px #0000000f;',
                        mb: 2
                    }}>

                    <CardContent>
                        <CardMedia
                            component="img"
                            height="140"
                            image={ad.logo}
                        />

                        <Typography
                            variant='h6'
                            align='center'
                        >
                            {ad.name}
                        </Typography>
                        <Typography
                            align='center'
                            color='text.secondary'
                        >
                            {ad.address}
                        </Typography>
                        <Logos website={ad.website} facebook={ad.facebook} instagram={ad.instagram}/>
                    </CardContent>
                </Card>

                {/*Комментирую это место так как не готов обрабатывать чужие емейлы пока*/}
                {/*<Card*/}
                {/*    className=''*/}
                {/*    sx={{*/}
                {/*        boxShadow: '0 0 0 1px #0000000f;',*/}
                {/*    }}>*/}
                {/*    <CardContent>*/}
                {/*        {price && <Typography*/}
                {/*            className="flex justify-around items-center"*/}
                {/*            mb={1}*/}
                {/*            component="div" variant="h5">*/}
                {/*        <span>*/}
                {/*             {price} €*/}
                {/*        <Typography*/}
                {/*            color="text.secondary"*/}
                {/*            component="span">/ hour*/}
                {/*        </Typography>*/}
                {/*        </span>*/}
                {/*            <Typography*/}
                {/*                sx={{}}*/}
                {/*                color="text.secondary"*/}
                {/*                component="span"> {min_time} hr. min*/}
                {/*            </Typography>*/}
                {/*        </Typography>}*/}
                {/*        <Button*/}
                {/*            sx={{minWidth: '100%', height: '45px'}}*/}
                {/*            variant="contained"*/}
                {/*            color="primary"*/}
                {/*            onClick={() => setModelOpen(true)}*/}
                {/*        >Message venue</Button>*/}
                {/*    </CardContent>*/}
                {/*</Card>*/}
            </div>

            <PlacePageModal isOpen={isOpenModel} setOpen={setModelOpen} ad={ad}/>
        </div>
    )
}
