import React from 'react';
import Carousel from 'react-material-ui-carousel'

export const PlacePageCarousel = ({images}) => {
    return images && (
        <>
            <DesktopCarousel images={images}/>
            <MobileCarousel images={images}/>
        </>
    )
}


const DesktopCarousel = ({images}) => (<div
    className='lg:flex h-[600px] hidden'
    id="carousel">

    <div
        className='w-full'
        id="main-image">
        <div
            style={{
                backgroundImage: `url(${images[0]})`
            }}
            className='flex items-end justify-center pb-10 bg-cover bg-center h-full w-full border-white border-y-4 border-r-2'>
        </div>
    </div>


    <div
        className='w-full flex items-end justify-center flex-wrap'
        id="secondary-image">
        <div
            style={{
                backgroundImage: `url(${images[1]})`
            }}
            className='bg-cover bg-center h-1/2 w-1/2 border-4 border-white'>
        </div>
        <div
            style={{
                backgroundImage: `url(${images[2]})`
            }}
            className='bg-cover bg-center h-1/2 w-1/2 border-4 border-white border-r-0'>
        </div>
        <div
            style={{
                backgroundImage: `url(${images[3]})`
            }}
            className='bg-cover bg-center  h-1/2 w-1/2 border-4 border-white'>
        </div>
        <div
            style={{
                backgroundImage: `url(${images[4]})`
            }}
            className='bg-cover bg-center h-1/2 w-1/2 border-4 border-white border-r-0'>
        </div>
    </div>
</div>)

export const MobileCarousel = ({images = []}) => {
    return images && (
        <Carousel
            animation='slide'
            navButtonsAlwaysVisible
            className='lg:hidden'
        >
            {
                images.map((image, i) => (<div
                    key={i}
                    style={{
                        backgroundImage: `url(${image})`,
                        height: '500px'
                    }}
                    className='flex items-end justify-center pb-10 bg-cover bg-center h-full w-full border-white border-y-4 border-r-2'>
                </div>))
            }
        </Carousel>
    )
}