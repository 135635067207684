import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {SearchFilter} from "../../components/SearchFilter";
import {getAds} from "../../api/supabase";
import {Ad, SupportedLanguages} from "../../types";

export const Selectors = () => {
    const [ads, setAdds] = useState<Ad[]>([])
    const [selectedCity, setSelectedCity] = useState( '')
    const [selectedAge, setSelectedAge] = useState( '')
    const [selectedActivity, setSelectedActivity] = useState( '')
    const {i18n} = useTranslation();
    const currentLanguage = i18n.resolvedLanguage;
    const allCities = [...new Set(ads.map((ad) => ad.city).filter(Boolean))];
    const allAges = [...new Set(ads.map(ad => ad.age_tags).flat().filter(Boolean))];
    const allActivities = [...new Set(ads.map(ad => ad.activity_tags).flat().filter(Boolean))];
    const navigate = useNavigate()
    const onSearch = () => {
        navigate('/map', {state: {city: selectedCity, age: selectedAge, activity: selectedActivity}})
    }

    useEffect(() => {
        const fetchAds = async () => {
            const ads = await getAds(currentLanguage as SupportedLanguages);
            setAdds(ads);
        }
        fetchAds()
            .catch(console.error);
    }, [currentLanguage])

    return (
        <div
            className='flex w-full justify-center flex-wrap gap-x-3 gap-y-3'
            id="selectors2">
            <SearchFilter
                onSearch={onSearch}

                selectedAge={selectedAge}
                ages={allAges}
                setAge={setSelectedAge}

                selectedActivity={selectedActivity}
                activities={allActivities}
                setActivity={setSelectedActivity}

                selectedCity={selectedCity}
                cities={allCities}
                setCity={setSelectedCity}/>

        </div>
    );
}