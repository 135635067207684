import React from 'react'

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export const Error = () => {
    return (
        <Card sx={{
            boxShadow: 'none'
        }}>
            <CardContent>
                <Typography>
                    Something went wrong
                </Typography>
                <Typography color='text.secondary'>
                    Please try sending the request again
                </Typography>
            </CardContent>
        </Card>
    )
}