import React from 'react'
import Typography from "@mui/material/Typography";
import {useAuth0} from "@auth0/auth0-react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import KeyIcon from '@mui/icons-material/Key';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import {Link} from "react-router-dom";
import {Header} from "../../components/Header";

export const Account = () => {
    const {user} = useAuth0();
    const isAdmin = user?.sub === process.env.ADMIN_ID

    return (
        <>
            <Header withBorder/>
            <div
                className='max-w-screen-lg mx-auto mt-10'
            >
                <Typography variant='h3'>
                    Account
                </Typography>
                <Typography fontSize={17} mb={3}>
                    <b>{user?.name}</b>, {user?.email}
                </Typography>

                <div className='grid grid-cols-1 md:grid-cols-3 gap-5'>
                    <Link to='personal'>
                        <Card>
                            <CardContent>
                                <div className='mb-5'>
                                    <EmojiPeopleIcon fontSize='large'/>
                                </div>
                                <Typography variant="h5" component="div">
                                    Personal info
                                </Typography>

                                <Typography variant="body2">
                                    Personal information and your details
                                </Typography>
                            </CardContent>
                        </Card>
                    </Link>
                    <Link to='bookings'>
                        <Card>
                            <CardContent>
                                <div className='mb-5'>
                                    <KeyIcon fontSize='large'/>
                                </div>

                                <Typography variant="h5" component="div">
                                    Bookings
                                </Typography>

                                <Typography variant="body2">
                                    Bookings have been made by you
                                </Typography>
                            </CardContent>
                        </Card>
                    </Link>

                    { isAdmin && <Link to='listings'>
                        <Card>
                            <CardContent>
                                <div className='mb-5'>
                                    <WarehouseIcon fontSize='large'/>
                                </div>
                                <Typography variant="h5" component="div">
                                    My properties
                                </Typography>

                                <Typography variant="body2">
                                    Listing of properties you offer
                                </Typography>
                            </CardContent>
                        </Card>
                    </Link>}

                    <Link to='help'>
                        <Card>
                            <CardContent>
                                <div className='mb-5'>
                                    <LiveHelpIcon fontSize='large'/>
                                </div>
                                <Typography variant="h5" component="div">
                                    Help center
                                </Typography>

                                <Typography variant="body2">
                                    Submit question and get help
                                </Typography>
                            </CardContent>
                        </Card>
                    </Link>

                </div>
            </div>
        </>
    )
}
