import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom';
import {getAd} from "../../api/supabase";
import {Header} from "../../components/Header";
import {PlacePageCarousel} from "./PlacePageCarousel";
import {Description} from "./Description";
import {Aside} from "./Aside";
import {Footer} from "../../components/Footer";

import {Ad, SupportedLanguages} from '../../types'
import {useTranslation} from "react-i18next";


export const PlacePage = () => {
    const {id} = useParams()
    const [ad, setAdd] = useState<Ad>()
    const { i18n} = useTranslation();
    const currentLanguage = i18n.resolvedLanguage;

    useEffect(() => {
        if (!id) return
        const fetchAds = async () => {
            const ads = await getAd(id, currentLanguage as SupportedLanguages);
            setAdd(ads);
        }
        fetchAds()
            .catch(console.error);
    }, [currentLanguage])

    return (
        <div>
            <Header/>
            <PlacePageCarousel images={ad?.hero_images}/>
            <div
                className='max-w-[1024px] w-auto m-auto flex my-5 lg:my-20 gap-16 flex-wrap relative'>
                <Description {...ad}/>
                <Aside {...ad} />
            </div>
            <Footer/>
        </div>
    )
}
