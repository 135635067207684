import { createClient } from '@supabase/supabase-js'
import {Ad, SupportedLanguages} from "../types";

// Create a single supabase client for interacting with your database
const supabase = createClient(process.env.SUPABASE_URL, process.env.SUPABASE_KEY)

export const getAds = async (currentLanguage: SupportedLanguages = 'en') => {
  let { data, error } = await supabase
    .from('ads')
    .select('*')
      .eq("is_published", true)


  console.log(data, error)
  return data.map(ad => getFieldsFilteredByLanguage(ad, currentLanguage));
}

export const getAllAds = async (currentLanguage: SupportedLanguages = 'en') => {
  let { data, error } = await supabase
      .from('ads')
      .select('*')

  return data.map(ad => getFieldsFilteredByLanguage(ad, currentLanguage));
}




export const getAd = async (id: string, currentLanguage: SupportedLanguages = 'en') => {
  let { data, error } = await supabase
    .from('ads')
    .select('*')
    .eq('id', id)

  console.log(data, error)
  return getFieldsFilteredByLanguage(data[0], currentLanguage);
}

const getFieldsFilteredByLanguage = (ad: Ad, language: SupportedLanguages) => {
  if (language === 'ee') return {
    ...ad,
    title: ad.title_ee || ad.title,
    description: ad.description_ee || ad.description,
    addons: ad.addons_ee && ad.addons_ee.length ? ad.addons_ee : ad.addons,
    rules: ad.rules_ee && ad.rules_ee.length ? ad.rules_ee : ad.rules,
    price_includes: ad.price_includes_ee && ad.price_includes_ee.length ? ad.price_includes_ee : ad.price_includes
  }
  if (language === 'ru') return {
    ...ad,
    title: ad.title_ru || ad.title,
    description: ad.description_ru || ad.description,
    addons: ad.addons_ru && ad.addons_ru.length ? ad.addons_ru : ad.addons,
    rules: ad.rules_ru && ad.rules_ru.length ? ad.rules_ru : ad.rules,
    price_includes: ad.price_includes_ru && ad.price_includes_ru.length ? ad.price_includes_ru : ad.price_includes
  }

  return ad;
}