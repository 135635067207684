import React from 'react'
import {Link} from "react-router-dom";
import {Box, CardActionArea, CardMedia, Chip} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import {Props} from "./types";


export const PreviewCard = ({id, title, district, name, image, to = `/places/${id}`, is_published, showStatus}: Props   ) => (
    <Link
        key={title}
        className='flex-1'
        to={to}>
        <Card

            sx={{border: 'none', minWidth: '250px'}}
            variant="outlined"
        >
            <CardActionArea>
                <CardMedia
                    className='transition-all'
                    sx={{height: 200, width: '100%'}}
                    component="div"
                    image={image}
                />
                {showStatus && <div className='absolute top-5 left-5'>

                    <Chip
                        label={is_published ? 'published' : 'unpublished'}
                        color={is_published ? 'success' : 'warning'}
                        size='small'
                    />

                </div>}
                <CardContent>
                    <Typography
                        sx={{fontWeight: 600}}
                        className='font-bold two-line-truncate'
                    >
                        {title}
                    </Typography>
                    <Typography
                        color="text.secondary"
                        component="span">
                        {name} - {district}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    </Link>
)
