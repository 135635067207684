import React from 'react'
import {CardActionArea, CardMedia} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";




export const ActivityCard = ({title, photoUrl}) => {
  return (
    <Card>
      <CardActionArea>
        <CardMedia
          sx={{ height: 230, width: 345 }}
          component="img"
          image={photoUrl}
        />
        <CardContent>
          <Typography
            textAlign='center'
            gutterBottom
            variant="h5"
            component="div">
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}


