import React from 'react'
import {Header} from '../../components/Header'
import {Footer} from "../../components/Footer";
import {useTranslation} from "react-i18next";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";

export const FAQ = () => {
    const {t} = useTranslation();

    return (
        <>
            <Header/>
            <div
                className='h-[calc(100%-276px)] flex justify-center items-center flex-col'
            >
                <div
                    className='font-bold text-3xl'
                >
                    {t('faq.title')}
                </div>
                <div
                    className='mb-4'>
                    <List sx={{width: '100%', bgcolor: 'background.paper'}}>
                        <ListUnit question={t('faq.question-1')} answer={t('faq.answer-1')} />
                        <ListUnit question={t('faq.question-2')} answer={t('faq.answer-2')} />
                        {/*<ListUnit question={t('faq.question-3')} answer={t('faq.answer-3')} />*/}
                    </List>

                </div>

            </div>
            <Footer/>
        </>
    )
}

const ListUnit = ({question, answer}) => (
    <ListItem alignItems="flex-start">
        <ListItemText
            primary={question}
            secondary={
                <React.Fragment>
                    <Typography
                        sx={{display: 'inline'}}
                        component="span"
                        variant="body2"
                        color="text.primary"
                    >
                        {answer} </Typography>
                </React.Fragment>
            }
        />
    </ListItem>
)