import React, {useEffect, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {Ad} from "../../../types";
import {getAd} from "../../../api/supabase";
import {useParams} from "react-router-dom";
import {JsonEditor} from "jsoneditor-react";
import Button from "@mui/material/Button";
import {Header} from "../../../components/Header";


export const EditListing = () => {
    const {id} = useParams()
    const {user} = useAuth0()
    const isMisha = user?.sub === process.env.ADMIN_ID
    const [ad, setAdd] = useState<Ad>(null)
    const onJsonChange = (json) => console.log(json)

    useEffect(() => {
        const fetchAds = async () => {
            const ad = await getAd(id);
            setAdd(ad);
        }
        if (user && isMisha) {
            fetchAds()
                .catch(console.error);
        }
    }, [user])
    console.log(ad)
    return (<>
        <Header withBorder/>
        <div className='max-w-screen-lg mx-auto mt-10'>
            {ad ? <JsonEditor value={ad} onChange={setAdd}/> : 'Loading...'}
            <div className='text-right'>
                <Button
                    variant='outlined'
                    onClick={console.log}>Save</Button>
            </div>

        </div>
    </>)
}