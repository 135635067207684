import React from 'react'

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export const Success = () => {
    return (
        <Card sx={{
            boxShadow: 'none'
        }}>
            <CardContent>
                <Typography>
                    Your message has been sent
                </Typography>
                <Typography color='text.secondary'>
                    Your host will contact you shortly
                </Typography>
            </CardContent>
        </Card>
    )
}