import React from 'react'
import {Header} from '../../components/Header'
import {Footer} from "../../components/Footer";
import {useTranslation} from "react-i18next";

export const Contacts = () => {
    const {t} = useTranslation();

    return (
        <>
            <Header/>
            <div
                className='h-[calc(100%-276px)] flex justify-center items-center flex-col'
            >
                <div
                className='font-bold'
                >
                    {t('contacts.title')}
                </div>
                <div
                    className='mb-4'

                >mikheyrojo@gmail.com</div>
                <img width='100' height='100' src='https://res.cloudinary.com/dhlzvktzj/image/upload/v1710593110/sunni/star-pirate_mpr4hk.svg' />

            </div>
            <Footer/>
        </>
    )
}