import {Box, CardMedia} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {ExplainingPoints} from "./ExplainingPoints";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";


export const Form = ({ad, onSubmit}) => {
    const [dateValue, setDate] = useState(null);
    const [startTimeValue, setStartTime] = useState(null);
    const [endTimeValue, setEndTime] = useState(null);
    const [comment, changeComment] = useState('')
    const [email, setEmail] = useState('')
    const {t} = useTranslation();


    const [isError, setError] = useState(null)

    const onButtonPress = () => {
        const isFilledIn = dateValue && startTimeValue && endTimeValue && email;
        if (!isFilledIn) {
            return setError(true)
        }
        onSubmit({
            date: dateValue?.format('DD MMMM YYYY'),
            start: startTimeValue?.format('HH:mm'),
            end: endTimeValue?.format('HH:mm'),
            total: endTimeValue.diff(startTimeValue, 'hours'),
            url: window.location.href,
            venue: ad.name,
            comment,
            email
        })
    }

    return (
        <>
            <Box
                className='flex-1'

                sx={{
                    minWidth: '260px',
                    mr: '20px'
                }}
            >
                <Card
                    sx={{
                        boxShadow: 'none'
                    }}
                >
                    <CardContent>
                        <CardMedia
                            component="img"
                            height="140"
                            image={ad.logo}
                        />
                        <Typography
                            fontStyle='bold'
                            variant='h6'
                            align='center'
                            m={1}
                        >
                            {ad.name}
                        </Typography>
                        <ExplainingPoints/>
                    </CardContent>
                </Card>
            </Box>

            <Box
                className='flex-1'
                sx={{
                    minWidth: '300px'
                }}
            >
                <Typography fontWeight='fontWeightMedium' fontSize={20}>
                    Message Host
                </Typography>

                <div
                    className='my-5'>
                    <DatePicker
                        disablePast
                        value={dateValue}
                        onChange={(e) => setDate(e)}
                        sx={{
                            width: '100%',
                            mb: 1
                        }}
                        className='my-10'
                        label="Date"/>
                    <div
                        className='mb-3'>
                        <TimePicker
                            timeSteps={{minutes: 60}}
                            value={startTimeValue}
                            onChange={(e) => setStartTime(e)}
                            sx={{width: '50%'}}
                            ampm={false}
                            label="Start time"/>
                        <TimePicker
                            ampm={false}
                            timeSteps={{minutes: 60}}
                            value={endTimeValue}
                            onChange={(e) => setEndTime(e)}
                            sx={{width: '50%'}}
                            label="End time"/>
                    </div>
                    <TextField
                        label="Email"
                        type='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        multiline
                        sx={{
                            width: '100%',
                            mb: 1
                        }}
                    />

                    <TextField
                        label="Comment"
                        value={comment}
                        onChange={(e) => changeComment(e.target.value)}
                        multiline
                        rows={4}
                        sx={{
                            width: '100%',
                            mb: 1
                        }}
                    />



                    <Button
                        sx={{minWidth: '100%', mb: 1}}
                        variant="contained"
                        color="primary"
                        onClick={onButtonPress}
                    >Message host</Button>
                    <Typography
                        className='text-orange-800'
                    >
                        {isError && 'Please fill in the date, interval and your email' }
                    </Typography>
                </div>
            </Box>
        </>)
}