import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import StarIcon from "@mui/icons-material/Star";
import ListItemText from "@mui/material/ListItemText";
import React from "react";

export const ExplainingPoints = () => (
    <List
        dense
        disablePadding
    >
        <ListItem sx={{paddingLeft: 0}}>
            <ListItemIcon sx={{minWidth: '30px'}}> <StarIcon/>
            </ListItemIcon>
            <ListItemText>
                <Typography
                    color='text.secondary'
                    fontSize={16}>
                    Select dates and times
                </Typography>
            </ListItemText>
        </ListItem>
        <ListItem sx={{paddingLeft: 0}}>
            <ListItemIcon sx={{minWidth: '30px'}}>
                <StarIcon/>
            </ListItemIcon>
            <ListItemText>
                <Typography
                    color='text.secondary'
                    fontSize={16}>
                    Write comment to the host
                </Typography>
            </ListItemText>
        </ListItem>
        <ListItem sx={{paddingLeft: 0}}>
            <ListItemIcon sx={{minWidth: '30px'}}>
                <StarIcon/>
            </ListItemIcon>
            <ListItemText>
                <Typography
                    color='text.secondary'
                    fontSize={16}>
                    Let your host know what you need
                </Typography>
            </ListItemText>
        </ListItem>
    </List>
)