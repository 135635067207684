import React from 'react'
import {ActivityCard} from "./Card";
import Typography from "@mui/material/Typography";
import {Selectors} from "./Selectors";
import {Footer} from "../../components/Footer";
import {useTranslation} from 'react-i18next'
import {Header} from "../../components/Header";
import {CARDS_LIST, getPhotoByKey} from "./helpers";


export const Landing = () => {
    const {t} = useTranslation();

    return (
        <main>
            <div
                id='hero'
                className='h-[700px] bg-cover bg-bottom w-full relative flex  justify-center items-center flex-col'
                style={{
                    backgroundImage: `url(https://res.cloudinary.com/dhlzvktzj/image/upload/v1710424121/sunni/kristina-evstifeeva-y3h6gh2fpaI-unsplash_fst2xv.jpg)`,
                    boxShadow: 'inset 0 0 0 10000px rgba(0, 0, 0, 0.5)'
                }}>
                <div className="absolute top-0 left-0 right-0 flex justify-between items-center">
                    <Header/>
                </div>
                <h1 className="text-3xl text-white px-3 text-center">{t('landing.hero-title')}</h1>

                <Selectors/>
            </div>
            <div
                className='mb-5'
                id='under-hero'>

                <Typography
                    variant="h4"
                    mt={5}
                    gutterBottom
                    className='text-3xl my-10 text-center'
                >
                    {t('landing.title2')}
                </Typography>
                <Typography
                    variant="h6"
                    mb={4}
                    gutterBottom
                    color="text.secondary"
                    className='text-3xl my-10 text-center'
                >
                    {t('landing.subtitle2')}
                </Typography>

                <div
                    id='slider'
                    className='flex justify-center items-center gap-4 flex-wrap'>
                    {
                        CARDS_LIST.map((key) => <ActivityCard
                            key={key} title={t(`landing.${key}`)}
                            photoUrl={getPhotoByKey(key)}/>)
                    }
                </div>
            </div>
            <Footer/>
        </main>
    )
}
