import React, {useEffect, useState} from 'react'
import Typography from "@mui/material/Typography";
import {PreviewCard} from "../../../components/PreviewCard";
import {Ad} from "../../../types";
import {getAllAds} from "../../../api/supabase";
import {useAuth0} from "@auth0/auth0-react";
import {AddNew} from "./AddNew";
import {Header} from "../../../components/Header";

export const Listings = () => {
    const {user} = useAuth0()
    const isMisha = user?.sub === process.env.ADMIN_ID
    const [ads, setAdds] = useState<Ad[]>([])

    useEffect(() => {
        const fetchAds = async () => {
            const ads = await getAllAds();
            setAdds(ads);
        }
        if (user && isMisha) {
            fetchAds()
                .catch(console.error);
        }
    }, [user])


    return (<>
        <Header withBorder/>
        <div
            className='max-w-screen-lg mx-auto mt-10'
        >

            <Typography variant='h3' mb={3}>
                My listings
            </Typography>

            <div className='grid grid-cols-1 md:grid-cols-3 gap-5'>

                {
                    ads.length
                        ? ads.map(({district, title, name, price, id, hero_images = [], is_published}) => (
                            <PreviewCard
                                id={id}
                                key={id}
                                image={hero_images?.['0']}
                                name={name}
                                district={district}
                                title={title}
                                to={`${id}`}
                                is_published={is_published}
                                showStatus
                            />
                        ))
                        : <Typography>No listings yet</Typography>
                }
                <AddNew/>
            </div>
        </div>
    </>)
}