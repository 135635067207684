import React from 'react'
import {createRoot} from 'react-dom/client';
import "mapbox-gl/dist/mapbox-gl.css";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './i18n'
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Landing} from "./pages/Landing";
import {MappedView} from "./pages/MappedView";
import {PlacePage} from "./pages/PlacePage";
import { Contacts } from './pages/Contacts';
import {FAQ} from "./pages/FAQ";
import {Terms} from "./pages/Terms";
import {Auth0Provider} from "@auth0/auth0-react";
import {Account} from "./pages/Account";
import {Listings} from "./pages/Account/Listings";
import {EditListing} from "./pages/Account/EditListing";

const root = createRoot(document.getElementById('root'));
const theme = createTheme({
    typography: {
        fontFamily: [
            'Lato',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    }

});

root.render(
    <Auth0Provider
        domain={process.env.AUTH0_DOMAIN}
        clientId={process.env.AUTH0_CLIENT_ID}
        authorizationParams={{
            redirect_uri: window.location.origin
        }}
    >
    <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Landing/>}/>
                    <Route path="map" element={<MappedView/>}/>
                    <Route path="places/:id" element={<PlacePage/>}/>
                    <Route path="contacts" element={<Contacts />}/>
                    <Route path="faq" element={<FAQ />}/>
                    <Route path="terms" element={<Terms />}/>
                    <Route path="account" element={<Account/>}/>
                    <Route path='account/listings' element={<Listings/>}/>
                    <Route path='account/listings/:id' element={<EditListing/>}/>
                </Routes>
            </BrowserRouter>
        </LocalizationProvider>
    </ThemeProvider>
    </Auth0Provider>
)
