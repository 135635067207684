import React from 'react'
import {Header} from '../../components/Header'
import {Footer} from "../../components/Footer";
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";

export const Terms = () => {
    const {t} = useTranslation();

    return (
        <>
            <Header/>
            <div
                className='min-h-[calc(100%-276px)] flex justify-center items-center flex-col'
            >
                <div
                    className='font-bold my-5  text-2xl'
                >
                    {t('terms.title')}
                </div>
                <div
                    className='mb-4 max-w-[50%]'>
                    <Typography paragraph>
                        {t('terms.paragraph-1')}
                    </Typography>
                    <Typography paragraph>
                        {t('terms.paragraph-2')}
                    </Typography>
                    <Typography paragraph>
                        {t('terms.paragraph-3')}
                    </Typography>

                </div>

            </div>
            <Footer/>
        </>
    )
}