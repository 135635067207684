import React, {useState} from 'react'
import Typography from "@mui/material/Typography";
import {ListItemButton, Popover} from "@mui/material";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import {useTranslation} from "react-i18next";

export const OptionSelector = ({options, onOptionChange, name, description, preselectedValue = ''}) => {
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [option, setOption] = useState(preselectedValue);
    const isOnlyOption = options.length === 1;
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const onTypeSelect = (option: string) => {
        setOption(option)
        handleClose()
        onOptionChange(option)
    }
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'option-selector' : undefined;

    return (
        <>
            <div
                onClick={handleClick}
                className='hover:bg-slate-100 rounded-3xl py-4 px-6 flex-1 cursor-pointer'
            >
                <Typography>
                    {name}
                </Typography>

                <Typography color='text.secondary'>
                    {option ? t(`filter.${option}`) : description}
                </Typography>
            </div>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <List>
                    <ListItemButton
                        onClick={() => onTypeSelect('')}
                        sx={{px: 5}}>
                        <ListItemText primary={t('filter.all')} />
                    </ListItemButton>
                    {
                        options.map(option => (
                            <ListItemButton
                                key={option}
                                onClick={() => onTypeSelect(option)}
                                sx={{px: 5}}>
                                <ListItemText primary={t(`filter.${option}`)}/>
                            </ListItemButton>
                        ))
                    }
                </List>
            </Popover>
        </>

    )
}