{
  "landing": {
    "hero-title": "Find Adventure & Celebration & Learning experiences",
    "title2": "Book amazing experience",
    "subtitle2": " The one you will remember for a long time",
    "bouldering": "Bouldering",
    "paintball": "Paintball",
    "escape-rooms": "Escape rooms",
    "kids-play-rooms": "Kids play rooms",
    "museums": "In museums",
    "outside": "Outside parties",
    "vr": "VR",
    "calligraphy": "Calligraphy"
  },
  "filter": {
    "all": "All",
    "city": "City",
    "selectCity": "Select a city",
    "age": "Age",
    "selectAge": "Select for whom",
    "activity": "Activity",
    "selectActivity": "Select type of activity",
    "Tallinn": "Tallinn",
    "kids": "Kids",
    "adults": "Adults",
    "teenager": "Teenagers",
    "birthdays": "Kids playground for birthdays",
    "sport": "Sport & Recreation",
    "art": "Arts & Crafts",
    "education": "Educations",
    "teambuilding": "Team building",
    "picnic": "Picnic",
    "events": "Events"
  },
  "ads": {
    "showing": "Showing",
    "out-of": "out of",
    "ads": "locations"
  },
  "selectors": {
    "find": "find"
  },
  "footer": {
    "contacts": "Contacts",
    "terms": "Terms of usage",
    "faq": "FAQ"
  },
  "contacts": {
    "title": "Contacts"
  },
  "faq": {
    "title": "FAQ",
    "question-1": "- What is happening here?",
    "answer-1": "- The website collects a catalog of venues for events such as corporate parties or birthdays.",
    "question-2": "- Do I need to pay or register?",
    "answer-2": "- No, we're a catalog, we help find venues for events and get in touch with them.",
    "question-3": "- When I send a message on the company's page with the date and a comment, what happens?",
    "answer-3": "- We redirect it to the company's email so they can get in touch with you."
  },
  "terms": {
    "title": "Terms of usage",
    "paragraph-1": "This website is owned by FIE Mikhail Stepanov (16370584), residing in Estonia. The site provides free and unrestricted access to all internet users. The main purpose of the website is to offer a directory service of establishments where celebrations and corporate events can be organized, as well as to facilitate contact with these establishments.",
    "paragraph-2": "It is important to note that this website does not collect or store user data. All information provided on the website is publicly accessible and subject to change by the establishments without prior notice. The site does not take responsibility for the accuracy of information regarding the locations and times of celebrations, nor does it verify their authenticity. Users are advised to independently verify information and clarify details directly with the establishments.",
    "paragraph-3": "By using this website, you automatically agree to the above terms. If you have any questions or suggestions regarding the use of the website, please contact us through the provided contact information."
  }
}