export const createBooking = async (params) => {
    try {
        const response = await fetch(`${process.env.API_URL}api/booking`, {
            method: 'POST',
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(params)
        });
        const body = await response.json();
        console.log(body)

        return {
            status: 'success',
            body
        }
    } catch (e) {
        console.log(e)
        return {
            status: 'error'
        }
    }


}