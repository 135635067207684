import React, {useEffect, useState} from 'react'
import {Ads} from "./Ads";
import {MainMap} from "./Map";
import {getAds} from "../../api/supabase";
import {Header} from "../../components/Header";
import {useLocation} from "react-router-dom";
import {Ad, SupportedLanguages} from "../../types";
import {useTranslation} from "react-i18next";
import {SearchFilter} from "../../components/SearchFilter";


export const MappedView = () => {
    const {state} = useLocation();
    const [ads, setAdds] = useState<Ad[]>([])
    const [selectedCity, setSelectedCity] = useState(state?.city || '')
    const [selectedAge, setSelectedAge] = useState(state?.age || '')
    const [selectedActivity, setSelectedActivity] = useState(state?.activity || '')

    const {i18n} = useTranslation();
    const [showMap, setShowMap] = useState(true)
    const currentLanguage = i18n.resolvedLanguage;
    const allCities = [...new Set(ads.map((ad) => ad.city).filter(Boolean))];
    const allAges = [...new Set(ads.map(ad => ad.age_tags).flat().filter(Boolean))];
    const allActivities = [...new Set(ads.map(ad => ad.activity_tags).flat().filter(Boolean))];


    useEffect(() => {
        const fetchAds = async () => {
            const ads = await getAds(currentLanguage as SupportedLanguages);
            setAdds(ads);
        }
        fetchAds()
            .catch(console.error);
    }, [currentLanguage])

    const filteredAds = ads.filter((ad) => {
        // @ts-ignore
        const ageFilter = Boolean(selectedAge) ? ad?.age_tags?.includes(selectedAge) : true
        // @ts-ignore
        const activityFilter = Boolean(selectedActivity) ? ad?.activity_tags?.includes(selectedActivity) : true
        const cityFilter = Boolean(selectedCity) ? ad?.city === selectedCity : true
        return cityFilter && activityFilter && ageFilter
    })

    return (
        <>
            <div className='shadow shadow-gray-10'>
                <Header>
                <SearchFilter
                    selectedAge={state?.age}
                    ages={allAges}
                    setAge={setSelectedAge}

                    selectedActivity={state?.activity}
                    activities={allActivities}
                    setActivity={setSelectedActivity}

                    selectedCity={state?.city}
                    cities={allCities}
                    setCity={setSelectedCity}/>
                </Header>
            </div>
            <div className='h-[calc(100%-120px)] flex'>
                <Ads
                    showMap={showMap}
                    setShowMap={setShowMap}
                    ads={filteredAds}
                    totalAdNumber={ads.length}
                />
                {showMap && <div className='w-[30%] h-full'>
                    <MainMap ads={filteredAds}/>
                </div>}
            </div>
        </>
    )
}
