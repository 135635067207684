import React, {useRef} from 'react';
import Map, {MapRef, Marker} from 'react-map-gl';
import mapboxgl from "mapbox-gl";
import {Ad} from "../../types";

export const MainMap = ({ads}: {ads: Ad[]}) => {
    const mapRef = useRef<MapRef>();
    const bounds = new mapboxgl.LngLatBounds()
    const popup = (text) => {
        return new mapboxgl.Popup().setText(text);
    };

    ads.forEach(ad => {
        bounds.extend([ad.longitude, ad.latitude])
    })

    mapRef.current?.fitBounds(
        bounds,
        {padding: 100, duration: 1000}
    );


    return (<Map
        ref={mapRef}
        mapboxAccessToken={process.env.MAP_TOKEN}
        cooperativeGestures
        initialViewState={{
            longitude: 24.75229102451432,
            latitude: 59.436508167483744,
            zoom: 10
        }}
        style={{height: '100%'}}
        mapStyle="mapbox://styles/mapbox/streets-v9"
    >
        {
            ads.map(({title, id, longitude, latitude}) => (
                <Marker
                    key={title + id}
                    longitude={longitude}
                    latitude={latitude}
                    popup={popup(title)}
                    color='red'
                    onClick={(args) => console.log(args)}
                />
            ))
        }

    </Map>)
}
